<template>
  <section>
     <lista :pg="this.pg" :id="this.id"/>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import lista from "@/components/escolaridade/escolaridadeAll.vue"

export default defineComponent({
  components:{
    lista
  },
  props: {
    pg: {
      default: 1,
    },
    id:{}
  },
  data() {
    return {
    };
  },
  async beforeMount() {
    if (this.id == null) {
      if (this.funcao === 1 || this.funcao === 2 || this.funcao === 7) {
        this.$router.push({ name: "servidores", params: { pg: this.pg } });
      } else {
        this.$router.push({
          name: "coordenacao-servidores",
          params: { pg: this.pg },
        });
      }
    }
  }
});
</script>
